.NiceTable .MuiTableCell-root {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 0px;
}

.NiceTable .MuiIconButton-root {
    padding: 1px;
}

