.unitEntryBase {
  padding-right: 13px;
  padding-top: 10px;
}

.unselectedUnitEntry {
  border: 1px transparent solid;
  font-weight: normal;
  color: black;
  background-color: white;
}

.selectedUnitEntry {
  border: 1px solid blue;
  font-weight: bold;
  color: black;
  background-color: #b5d1ff;
}

.focusedUnitEntry {
  border: 1px solid #4287f5;
  font-weight: bold;
  color: black;
  background-color: #c0d9ff;
}

.unitIdsColumnLabelStyle {
  min-width: 200px;
  font-weight: bold;
  padding: 7px 5px 7px 5px;
}

.unitLabelsStyle {
  padding-right: 3px;
  color: #333333;
}

.plotUnitLabel {
  text-align: center;
  font-weight: bold;
}

.plotWrapperStyle {
  min-height: 228px;
  min-width: 206px;
}

.plotWrapperStyleButton {
  padding-top: 100px;
}

.plotUnselectedStyle {
  border: 3px solid transparent;
}

.plotFocusedStyle {
  border: 3px solid #4287f5;
  background-color: #b5d1ff;
}

.plotSelectedStyle {
  border: 3px solid blue;
  background-color: #b5d1ff;
}
